<template>
  <div>
    <v-row align="center" justify="center">
      <v-dialog
        :overlay-color="'#000000'"
        :overlay-opacity="0.9"
        v-model="openModal"
        max-width="500"
      >
        <v-card width="500" class="pr-10 pl-10 pt-4 pb-4" min-height="400">
          <v-icon class="float-right mt-3 mr-n5" @click="openModal = false"
            >mdi-close</v-icon
          >
          <v-card-title>
            <h4 class="white--text ml-n10 mb-5" style="font-weight: 100">
              Report Details
            </h4>
          </v-card-title>
          <v-row class="ml-n4 mt-5 mb-5">
            <v-card-text v-if="reportDetails">
              <table style="width: 100%">
                <tbody>
                  <tr>
                    <td class="pa-1">Client:</td>
                    <td class="pa-1" v-if="profile">{{ profile.name }}</td>
                  </tr>
                  <tr>
                    <td class="pa-1">Type:</td>
                    <td class="pa-1">{{ reportTypeDisplay }}</td>
                  </tr>
                  <tr>
                    <td class="pa-1">ID:</td>
                    <td class="pa-1">{{ reportDetails.reportId }}</td>
                  </tr>

                  <tr>
                    <td class="pa-1">Created:</td>
                    <td class="pa-1">
                      {{ formatDate(reportDetails.reportInstance.created_at) }}
                    </td>
                  </tr>
                  <tr>
                    <td class="pa-1">Completed:</td>
                    <td class="pa-1">
                      {{
                        formatDate(reportDetails.reportInstance.completed_at)
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td class="pa-1">Notes:</td>
                    <td class="pa-1">
                      {{ reportDetails.reportInstance.notes }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-card-text>
          </v-row>
        </v-card>
      </v-dialog>
      <v-alert height="80" dark width="960px" prominent class="mb-8 mt-3">
        <v-row class="d-flex no-gutters">
          <v-col cols="10">
            <h2 class="mt-1 font-weight-light">
              <span v-if="profile">{{ profile.name }}</span> -
              <span v-if="reportTypeDisplay">{{ reportTypeDisplay }}</span>
            </h2>
          </v-col>

          <v-col cols="2" v-if="reportDetails">
            <v-btn
              color="primary"
              class="mt-1 ml-16"
              icon
              @click="openModal = true"
            >
              <v-icon>mdi-information-outline</v-icon></v-btn
            >
            <v-btn
              v-if="reportStatus()"
              color="primary"
              class="mt-1 ml-2"
              icon
              text
              href="#"
              id="downloadResults"
            >
              <v-icon>mdi-download</v-icon></v-btn
            >
          </v-col>
        </v-row>
      </v-alert>

      <v-row>
        <div class="mx-auto" style="max-width: 960px">
          <v-slide-group
            v-model="summary"
            show-arrows
            center-active
            v-if="reportDetails"
            prev-icon="mdi-arrow-left-box"
            next-icon="mdi-arrow-right-box"
          >
            <v-slide-item
              v-for="(value, name, index) in reportDetails.summary"
              :key="index"
              v-slot="{ active, toggle }"
            >
              <div @click="generateTable(name, value)">
                <v-btn
                  v-if="name !== 'report_card'"
                  class="mx-2 body-1 mb-5 mt-5"
                  :input-value="active"
                  active-class="error white--text"
                  depressed
                  @click="toggle"
                >
                  {{ name }}
                </v-btn>
              </div>
            </v-slide-item>
          </v-slide-group>
        </div>
      </v-row>
      <v-row>
        <div
          class="mx-auto mt-5 reportDetails"
          style="max-width: 960px; min-width: 960px"
        >
          <div>
            <v-card style="width: 100%" class="mt-n4" flat id="messageCard">
              <v-card-title v-if="loading == true">Loading....</v-card-title>
              <v-progress-linear
                indeterminate
                color="primary"
                v-if="loading == true"
              ></v-progress-linear>
              <v-card-title v-if="showError == true"
                >Sorry, an error occurred.<span v-if="showMsg"
                  >&nbsp;{{ showMsg }}</span
                ></v-card-title
              >
            </v-card>
          </div>
          <div v-for="(tableHeaders, index) in headers" :key="index">
            <v-card-title class="ml-n1 mb-n5" v-if="titles">{{
              titles[index].toUpperCase()
            }}</v-card-title>
            <v-card class="mt-5 mb-5" style="background: transparent" flat>
              <v-text-field
                v-model="search[index]"
                placeholder="Filter Results"
                style="max-width: 250px"
                class="ml-4 mt-n5"
              ></v-text-field>
              <v-data-table
                :headers="tableHeaders"
                :items="rows[index]"
                :items-per-page="10"
                :search="search[index]"
                class="elevation-0 newlines"
                style="background: #112748"
              ></v-data-table
            ></v-card>
          </div>
        </div>
      </v-row>
    </v-row>

    <v-fade-transition>
      <v-btn
        v-scroll="onScroll"
        v-show="fab"
        fab
        dark
        fixed
        bottom
        right
        outlined
        color="white"
        @click="toTop"
      >
        <v-icon>mdi-arrow-up-bold</v-icon>
      </v-btn>
    </v-fade-transition>
  </div>
</template>
<script>
import store from "../store/index";
import {
  reportTypesGet,
  getReport,
  getCustomer,
  getReportArchive,
  getReportComponentJson,
  getReportComponentCsv,
} from "../../src/clients/tracelight";
import axios from "axios";
import { formatDate } from "@/utils/common";
import Papa from "papaparse";
export default {
  name: "ReportDetails",
  components: {},
  data() {
    return {
      loading: false,
      reportDetails: null,
      profile: null,
      clientId: null,
      reportId: null,
      reportType: null,
      reportTypeDisplay: null,
      openModal: false,
      formatDate: formatDate,
      allReports: [],
      tab: null,
      showError: false,
      showMsg: null,
      fab: false,
      summary: 0,
      rows: [],
      headers: [],
      search: [],
      titles: [],
    };
  },
  mounted() {
    const style = document.createElement("style");
    style.appendChild(
      document.createTextNode(
        "td {max-width: 250px !important; white-space: nowrap !important; overflow: hidden !important; text-overflow: ellipsis !important;}"
      )
    );
    document.querySelector("head").appendChild(style);
  },
  created() {
    this.clientId = this.$route.params.clientId;
    this.reportId = this.$route.params.reportId;
    this.reportType = this.$route.params.reportType;
    const payload = {};
    const params = {
      client_id: this.clientId,
      report_id: this.reportId,
      report_type: this.reportType,
    };
    const param = {
      client_id: this.clientId,
    };
    const par = {
      client_id: this.clientId,
      report_id: this.reportId,
      report_type: this.reportType,
      csvs_only: true,
    };
    getCustomer(payload, param).then((result) => {
      this.profile = result.data.data;
    });
    reportTypesGet(payload, {
      results_per_page: 3000,
      report_type: this.reportType,
    })
      .then((result) => {
        store.commit("customerList/fillReportTypes", result.data.data);

        const rt = this.reportTypes.filter(
          (type) => type.id == this.reportType
        );

        if (rt.length > 0) {
          this.reportTypeDisplay = rt[0].name;
        }
      })
      .catch((error) => {
        console.error(error);
        alert(error);
      });

    getReportArchive(payload, par)
      .then(function (res) {
        let downloadResults = document.getElementById("downloadResults");
        downloadResults.href = res.data.data.s3.url;
      })
      .catch(function (error) {
        console.warn(error);
      });
    getReport(payload, params)
      .then((result) => {
        if (result.data.data.errorMessage) {
          console.warn(result.data.data);
          this.showError = true;
          this.showMsg = result.data.data.errorMessage;
          return;
        } else {
          this.reportDetails = result.data.data;
          console.warn(result.data.data);
          this.reportStatus();
          this.generateTable(
            Object.entries(this.reportDetails.summary)[0][0],
            Object.entries(this.reportDetails.summary)[0][1]
          );
        }
      })
      .catch((error) => {
        this.showError = true;
        console.warn(error);
      });
  },
  methods: {
    reportStatus() {
      let status = false;
      if (this.reportDetails.reportInstance.error !== null) {
        status = false;
      } else if (
        this.reportDetails.reportInstance.completed_at == null &&
        this.reportDetails.reportInstance.error == null
      ) {
        status = false;
      } else {
        status = true;
      }
      return status;
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 100;
    },
    toTop: function () {
      this.$vuetify.goTo(0);
    },
    generateTable(name, item) {
      this.rows = [];
      this.headers = [];
      this.search = [];
      this.titles = [];
      //this.loading = true;
      console.log(name, item);
      setTimeout(() => {
        if (item.csv) {
          for (const [key, value] of Object.entries(item.csv)) {
            const payload = {};
            if (name == "vt" || name == "google") {
              name = "dataloss";
            }
            const params = {
              client_id: this.clientId,
              report_id: this.reportId,
              report_type: this.reportType,
              component: name,
              csvtype: key,
            };

            getReportComponentCsv(payload, params)
              .then((result) => {
                console.log(result);
                if (result.data.data.errorMessage) {
                  this.loading = false;
                  this.showError = true;
                  this.showMsg = result.data.data.errorMessage;
                  return;
                } else {
                  axios({
                    method: "get",
                    url: result.data.data.s3.url,
                  }).then((r) => {
                    console.log(r);
                    if (r.data.length > 0) {
                      this.loading = false;
                      const tableHeaders = [];
                      const headerKeys = [];
                      const parsed = Papa.parse(r.data);
                      /* let rowStrings = r.data.trim().split(/\r?\n/); */
                      let rowStrings = parsed.data;
                      let headerString = rowStrings.shift();
                      for (let column of headerString) {
                        const text = column.trim();
                        const key = text.toLowerCase().replace(/\s/g, "");
                        tableHeaders.push({
                          text: text,
                          value: key,
                        });
                        headerKeys.push(key);
                      }
                      this.headers.push(tableHeaders);
                      this.titles.push(key);
                      const tableData = [];
                      for (let [_, rowString] of rowStrings.entries()) {
                        let row = {};
                        for (let [index, column] of rowString.entries()) {
                          row[headerKeys[index]] = column.trim();
                        }
                        tableData.push(row);
                      }
                      this.rows.push(tableData);
                    }
                  });
                }
              })
              .catch((error) => {
                this.showError = true;
                console.warn(error);
              });
          }
        } else {
          this.loading = false;
        }
      }, 500);
    },
  },
  computed: {
    reportTypes() {
      return store.state.customerList.reportTypes || [];
    },
  },
};
</script>
<style scoped>
.newlines td {
}

.reportDetails >>> td {
  white-space: normal !important;
}

.v-list .v-list-item--active,
.v-btn--active {
  pointer-events: none;
}
.theme--dark.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: transparent;
}
#messageCard >>> .v-card__title {
  font-size: 1.3em;
}
</style>
